import * as _ from 'lodash'
import { FormStrategy } from './form-strategy'
import { FIELDS } from '../../constants/roles'

const getFields = $w => {
  const email: any = _($w(`@${FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL}`)).first()
  const password: any = _($w(`@${FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD}`)).first()
  return { email, password }
}

const submitFields = fields =>
  fields.filter(field => _.get(field, 'connectionConfig.fieldType') !== 'password')

const field2ContactMap = {
  email: 'loginEmail',
}
const getContactInfo = fields =>
  fields.reduce((contactInfo, field) => {
    const { crmType, crmLabel, fieldType } = field.connectionConfig
    const isCustomField = crmType === 'customField' && crmLabel && fieldType !== 'password'
    if (isCustomField) {
      contactInfo[crmLabel] = field.value
    } else if (crmType !== 'customField') {
      contactInfo[field2ContactMap[crmType] || crmType] = field.value
    }
    return contactInfo
  }, {})

export class RegistrationFormStrategy extends FormStrategy {
  static isEnabled($w) {
    const { email, password } = getFields($w)
    return email && password
  }

  async execute({ attachments, fields }) {
    const { $w, wixUsers } = this.submitArgs
    const { email, password } = getFields($w)
    try {
      const contactInfo = getContactInfo(fields)
      await wixUsers.register(email.value, password.value, { contactInfo })
    } catch (e) {
      return
    }
    return super.execute({ attachments, fields: submitFields(fields) })
  }

  async postSubmission() {
    const { wixWindow } = this.submitArgs
    setTimeout(() => wixWindow.lightbox.close(), 750)
  }
}
