import 'whatwg-fetch'
import Experiments from 'wix-experiments'
import { ROLE_SUBMIT_BUTTON, ROLE_MESSAGE, ROLE_FORM, FIELDS_LIST } from '../constants/roles'
import { initBiLogger } from '../utils/bi'
import { getSentryDSN, getAppVersionFromUrl } from '../utils/utils'
import { EVENTS } from '../constants/bi'
import * as _ from 'lodash'
import * as Raven from 'raven-js'
import { SUBMISSION_DISPLAY_FIELD } from '../constants/wixcode'
import { isUploadButton, isCheckbox, escapeRegExp, innerText } from './viewer-utils'
import { createWixData } from './wix-data'
import { getInputValue } from './input-value'
import { strategies } from './strategy/strategies'

const ERROR_COLOR = '#FF4040'
const ERRORS = {
  FILE_UPLOAD: 'File could not be uploaded. Try again or choose a different file.',
  SUBMISSION:
    'Sorry, something went wrong and the form was not submitted. Try again now or later if the problem persists.',
}
const ERROR_TYPES = {
  UPLOAD_FILE: 'UPLOAD_FILE_ERROR',
}

const viewerEvents = EVENTS.VIEWER_APP

Raven.config(getSentryDSN('viewer-app'), {
  logger: 'logger-viewer-app',
  release: getAppVersionFromUrl('viewer-app'),
})

let initInstance
let biLogger: any = {}
let linksUtil
let wixData
let viewerAppUrl
let resolveExperiments
const experimentsPromise: Promise<Experiments> = new Promise(resolve => {
  resolveExperiments = resolve
})
const getExperiments = () => experimentsPromise

const getErrorModalUrl = msg =>
  viewerAppUrl &&
  viewerAppUrl
    .split('/')
    .slice(0, -1)
    .concat(['statics', `viewer-modal-panel.html?msg=${msg}`])
    .join('/')

const displayErrorModal = async ({ msg, wixWindow, width = 445, height = 250 }) => {
  const experiments = await getExperiments()
  if (experiments.enabled('specs.cx.FormBuilderServerErrorModal')) {
    wixWindow.openModal(getErrorModalUrl(msg), { width, height })
  }
}

const initAppForPage = ({ instance, url }, { links }) => {
  linksUtil = links
  initInstance = instance
  viewerAppUrl = url

  wixData = createWixData(self.elementorySupport)

  resolveExperiments(new Experiments({ baseUrl: 'https://www.wix.com', scope: 'wix-form-builder' }))
  return Promise.resolve()
}

const getVisitorId = () => initInstance && JSON.parse(atob(initInstance.split('.')[1])).aid

const getFormName = $w => {
  const form = $w(`@${ROLE_FORM}`)
  return {
    form_comp_id: form.uniqueId,
    form_name: form.connectionConfig.formName,
  }
}

const getFormParamsForBi = ($w, fields, wixLocation) => ({
  visitor_id: getVisitorId(),
  num_of_attachments: getAttachmentsCount(fields),
  form_url: wixLocation.url || '',
  ...getFormName($w),
})

const getSubmitErrorParamsForBi = ($w, fields, wixLocation, reason, reason_body) => ({
  reason,
  reason_body,
  ...getFormParamsForBi($w, fields, wixLocation),
})

const getAttachmentsCount = fields =>
  _.filter(fields, field => isUploadButton(field) && field.value.length > 0).length

const getFieldValidity = fields => {
  const errorOrder = [
    'valueMissing',
    'fileNotUploaded',
    'typeMismatch',
    'patternMismatch',
    'rangeOverflow',
    'rangeUnderflow',
    'stepMismatch',
    'tooLong',
    'tooShort',
    'badInput',
    'customError',
  ]
  const errorType = _.find(errorOrder, error => _.some(fields, `validity.${error}`))
  const field = _.find(fields, field => field.validity[errorType])
  return `${errorType} : ${_.get(field, 'connectionConfig.fieldType')}`
}

const isTemplate = wixLocation => !wixLocation.baseUrl

const getAttachments = ($w, fields, { wixLocation, wixWindow }) => {
  return Promise.all(
    _.filter(fields, field => isUploadButton(field) && field.value.length > 0).map(async field => {
      try {
        const { url } = await field.startUpload()
        return {
          url,
          name: field.value[0].name,
        }
      } catch (uploadError) {
        displayErrorModal({ wixWindow, msg: ERRORS.FILE_UPLOAD })

        uploadError.errorType = ERROR_TYPES.UPLOAD_FILE

        biLogger.log({
          evid: viewerEvents.SUBMISSION_FAILURE,
          ...getSubmitErrorParamsForBi(
            $w,
            fields,
            wixLocation,
            'media manager error',
            uploadError.errorDescription
          ),
        })
        throw uploadError
      }
    })
  )
}

const showFormError = (message, errorMessage) => {
  if (!message.html) {
    return
  }
  const colorRegExp = /color: ?[^;"]+/
  let htmlErrorMessage = errorMessage
  if (message.html.indexOf(colorRegExp) === -1) {
    htmlErrorMessage = `<span style="color: ${ERROR_COLOR}">${htmlErrorMessage}</span>`
  }
  message.html = message.html
    .replace(colorRegExp, `color: ${ERROR_COLOR}`)
    .replace(new RegExp(`>${escapeRegExp(innerText(message.html))}`), `>${htmlErrorMessage}`)
  message.show()
}

const resetFields = fields => {
  fields.forEach(field => {
    if (isUploadButton(field)) {
      field.reset()
      return
    }
    if (isCheckbox(field)) {
      field.checked = false
    }
    field.value = null
    if ('resetValidityIndication' in field) {
      field.resetValidityIndication()
    }
  })
}

const getSubmitButton = $w => $w(`@${ROLE_SUBMIT_BUTTON}`)[0]

const onSubmit = async ({ $w, collectionId, $message = {}, wixLocation, wixWindow }, strategy) => {
  const $submitButton = getSubmitButton($w)
  $submitButton.disable()
  const fields = FIELDS_LIST.reduce((res, roleField) => res.concat($w(`@${roleField}`)), [])

  try {
    biLogger.log({
      evid: viewerEvents.USER_CLICKS_SUBMIT,
      ...getFormParamsForBi($w, fields, wixLocation),
    })

    fields.forEach(field => field.updateValidityIndication())
    const isAllValid = strategy.validateFields(fields)

    if (!isAllValid) {
      biLogger.log({
        evid: viewerEvents.SUBMISSION_FAILURE,
        ...getSubmitErrorParamsForBi(
          $w,
          fields,
          wixLocation,
          'field validity',
          getFieldValidity(fields)
        ),
      })

      $submitButton.enable()
      return
    }

    const attachments = await getAttachments($w, fields, { wixLocation, wixWindow })
    const serverRequest =
      !isTemplate(wixLocation) && (await strategy.execute({ attachments, fields }))
    if (isTemplate(wixLocation) || (serverRequest && serverRequest.ok)) {
      biLogger.log({
        evid: viewerEvents.SUBMISSION_SUCCESS,
        ...getFormParamsForBi($w, fields, wixLocation),
      })

      if (collectionId) {
        const toInsert = {
          [SUBMISSION_DISPLAY_FIELD]: new Date(),
        }
        fields.forEach(field => {
          if (!_.get(field, 'connectionConfig.collectionFieldKey')) {
            return
          }

          let value = getInputValue(field, attachments)
          if (isUploadButton(field)) {
            value = _.get(value, 'url')
          }

          if (isCheckbox(field)) {
            value = value ? 'V' : ''
          }

          toInsert[field.connectionConfig.collectionFieldKey] = value
        })

        await wixData.insert(collectionId, toInsert)
      }

      await strategy.postSubmission()
      resetFields(fields)
    } else {
      displayErrorModal({ wixWindow, msg: ERRORS.SUBMISSION })

      biLogger.log({
        evid: viewerEvents.SUBMISSION_FAILURE,
        ...getSubmitErrorParamsForBi($w, fields, wixLocation, 'server error', serverRequest.status),
      })
    }

    $submitButton.enable()
  } catch (err) {
    $submitButton.enable()

    if (err.errorType !== ERROR_TYPES.UPLOAD_FILE) {
      biLogger.log({
        evid: viewerEvents.SUBMISSION_FAILURE,
        ...getSubmitErrorParamsForBi($w, fields, wixLocation, 'client error', err.name),
      })

      console.error(`form submit failed with: ${err}`) //eslint-disable-line no-console

      if (!err.errorType) {
        showFormError($message, `Something went wrong. Please try again later`) // FIXME - eager i18n for error message
      }
    }

    Raven.captureException(err)
  }
}

const registerSubmitButtonIfExists = ($w, submitArgs) => {
  const $submitButton = getSubmitButton($w)
  const strategy = _.find(strategies, s => s.isEnabled($w))
  if (!$submitButton || !strategy) {
    return
  }
  $submitButton.onClick(
    Raven.wrap(() => onSubmit(submitArgs, new strategy(submitArgs, initInstance, linksUtil)))
  )
  // FIXME - Check why Raven.wrap does not catch exception (replaced throw err with captureException to overcome this for now)
}

const pageReadyImpl = ($w, { window: wixWindow, location: wixLocation, user: wixUsers }) => {
  try {
    Raven.setUserContext({ id: `${wixLocation.url}` })
  } catch (err) {
    Raven.captureException(err)
  }
  if (!$w(`@${ROLE_FORM}`).length) {
    return
  }
  biLogger = initBiLogger({ defaults: { msid: $w(`@${ROLE_FORM}`).connectionConfig.msid } })
  const { collectionId, secondsToResetForm, successActionType, successLinkValue } = $w(
    `@${ROLE_FORM}`
  ).connectionConfig
  const $message: any = $w(`@${ROLE_MESSAGE}`)
  const successMessage = $message.html
  let submitArgs: any = {
    $w,
    collectionId,
    successMessage,
    secondsToResetForm,
    successActionType,
    successLinkValue,
    wixLocation,
    wixWindow,
    wixUsers,
  }

  if ($message.hide) {
    $message.hide()
    submitArgs = { ...submitArgs, $message }
  }

  registerSubmitButtonIfExists($w, submitArgs)
}

const createControllers = Raven.wrap(controllerConfigs => {
  return controllerConfigs.map(() =>
    Promise.resolve({
      pageReady: Raven.wrap(pageReadyImpl),
    })
  )
})

module.exports = { initAppForPage, createControllers }
